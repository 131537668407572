import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

import { Flex, Text } from "../styled";
import Button from "./button";
import Wrapper from "./wrapper";

import logo from "../images/img-main-stage.jpg";

const BG = styled.div`
  position: relative;
  width: 100%;
  min-height: 350px;
  background-size: cover !important;
  z-index: 0;
  border-radius: 15px;
`;

export default ({
  imageFirst = false,
  image = logo,
  title = "",
  desc = "",
  to,
  actionLabel = "Anfrage Senden",
}) => {
  return (
    <Flex mt={100} relative>
      <Wrapper
        justifyContent="space-between"
        alignItems="center"
        flexDirection={
          imageFirst ? ["column", "row"] : ["column", "row-reverse"]
        }
        zIndex={8888}
      >
        <Flex
          w="100%"
          mr={imageFirst ? [0, 100] : 0}
          ml={imageFirst ? 0 : [0, 100]}
        >
          <BG
            style={{
              background: `url(${image}) no-repeat center center`,
            }}
          />
        </Flex>

        <Flex alignItems="flex-start" w="100%" p={[20, 30]}>
          <Text font="h3" as="h3" color="secondary">
            {title}
          </Text>
          <Text>{parse(desc)}</Text>
          {to ? (
            <Button mt={25} to={to}>
              {actionLabel}
            </Button>
          ) : null}
        </Flex>
      </Wrapper>
    </Flex>
  );
};
