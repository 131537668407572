import * as React from "react";
import Faq from "react-faq-component";

import { Flex, Text, useTheme } from "../styled";
import Wrapper from "./wrapper";

const data = {
  rows: [
    {
      title: "Welche Arten von Wunden versorgt WKM Rösner?",
      content: `Wir versorgen alle Wunden die in einem  angemessenem Zeitraum nicht abheilen und somit als chronische Wunde bezeichnet werden. Darunter fallen Wunden unterschiedlicher Ursachen wie z.B. Offene Beine Ulcus cruris, Diabetischer Fuß, Dekubitusgeschwüre Tumorwunden, aber auch nicht heilende Wunden nach Operationen, Verbrennungen, Verletzungen jeglicher Art.`,
    },
    // {
    //   title: "Kann ich zur Begutachtung meiner Wunde einfach vorbeikommen?",
    //   content: `Missing`,
    // },
    {
      title: "Benötige ich eine Überweisung?",
      content: `Eine Überweisung benötigen Sie nicht.`,
    },
    {
      title: "Was kostet die Behandlung bei WKM Rösner?",
      content: `Die Wundversorgung durch das WKM Team ist grundsätzlich kostenlos. Wenn Sie uns zu Rate ziehen, erhalten Sie eine kompetente Beurteilung und zielgerichtete Therapie der Wunde sowie weiterführende Beratungsgespräche. Ebenfalls inbegriffen sind das Anlegen und Wechseln von Wundverbänden sowie Kompressionsverbänden. Die einzigen Kosten die Ihnen entstehen sind gesetzliche Rezept- und Verordnungsgebühren sowie Kosten für Verbrauchsmaterialien wie z.B Wundspüllösungen oder Hautpflegepräperate.`,
    },
    // {
    //   title: "Wird die Behandlung von der gesetzlichen Krankenkasse getragen?",
    //   content: `Missing`,
    // },
  ],
};

const FaqView = () => {
  const theme = useTheme();
  const styles = {
    // bgColor: 'white',
    titleTextColor: theme.colors.secondary,
    rowTitleColor: theme.colors.secondary,
    // rowContentColor: 'grey',
    arrowColor: theme.colors.primary,
  };
  return (
    <>
      <Flex bg="#FFF">
        <Wrapper maxWidth={700} pt="10vh" pb="10vh">
          <Flex alignItems="center" w="100%">
            <Text font="h4">Häufig gestellte Fragen</Text>
            <Faq data={data} styles={styles} />
          </Flex>
        </Wrapper>
      </Flex>
    </>
  );
};

export default FaqView;
