import React from "react";
import styled from "styled-components";

import { Flex, Text } from "../styled";
import Wrapper from "./wrapper";

const Avatar = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-size: cover !important;
  z-index: 0;
`;

const TeamImage = styled.div`
  width: 100vw;
  height: 40vw;
  background-size: cover !important;
  z-index: 0;
`;

const team = [
  {
    name: "Friederike Rösner",
    image: require("../images/roenser.png"),
    job: "Inhaberin WKM",
    info:
      "Zertifizierte Wundmanagerin nach §64GuKG ZWM®, ZWMcert®, AZWM®, Akademischer BO",
  },
  {
    name: "Sarah Wünsche",
    image: require("../images/wunsche.png"),
    job: "Gesundheits- und Krankenpflegerin",
    info: "Zertifizierte Wundmanagerin ZWM® nach §64 GuKG",
  },
  {
    name: "Andreas Heinze",
    image: require("../images/heinze.png"),
    job: "Examinierter Krankenpfleger",
    info: "Zertifizierter Wundmanager ZWM® nach § 64 GuKG",
  },
  // {
  //   name: "Tanja Krüger",
  //   image: require("../images/kruger.png"),
  //   job: "Examinierte Pflegefachkraft",
  //   info: "Zertifizierte Wundmanagerin ZWM® nach §64 GuKG",
  // },
  // {
  //   name: "Tanja Krüger",
  //   image: require("../images/team_2.png"),
  //   job: "Examinierte Pflegefachkraft",
  //   info: "Zertifizierte Wundmanagerin ZWM®",
  // },
];

export const TeamItem = ({
  name = "",
  job = "",
  info = "",
  image = require("../images/shutterstock_221398282.jpg"),
}) => {
  return (
    <Flex alignItems="center" w={["100%", "50%", "25%"]} mb={30} px={30}>
      <Avatar
        style={{
          background: `url(${image}) no-repeat center center`,
        }}
      />
      <Text font="h5" textAlign="center" mt={10}>
        {name}
      </Text>
      <Text mt={10} textAlign="center">
        {job}
      </Text>
      <Text font="label" textAlign="center" mt={10}>
        {info}
      </Text>
    </Flex>
  );
};

export default ({}) => {
  return (
    <Flex style={{ position: "relative" }}>
      <Wrapper maxWidth={700} pt="10vh">
        <Flex alignItems="center" w="100%">
          <Text textAlign="center" font="h4">
            Das WKM Rösner Team
          </Text>
          <Text textAlign="center">
            Unsere Mitarbeiter sind nach dem Curriculum der Akademie
            Kammerlander ausgebildet. Diese Ausbildung gewährleistet ein hohes
            Maß an kontinuierlicher Qualität und Therapiesicherheit in der
            Wundversorgung. Alle Mitarbeiter haben nach bestandener Prüfung eine
            Einzelpersonenzertifizierung absolviert.
          </Text>
        </Flex>
      </Wrapper>
      <Wrapper pb="10vh" pt={30}>
        <Flex w="100%" row wrap mt={30}>
          {team.map((item, i) => {
            return <TeamItem key={`${i}-team`} {...item} />;
          })}
        </Flex>
      </Wrapper>
      <Flex h="40vw" style={{ position: "relative" }}>
        <TeamImage
          style={{
            background: `url(${require("../images/team.jpg")}) no-repeat center center`,
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "30%",
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 9999,
            background:
              "linear-gradient(180deg,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%)",
          }}
        />
      </Flex>
    </Flex>
  );
};
