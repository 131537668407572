import React from "react";
import styled from "styled-components";

import { Flex, Text } from "../styled";
import Button from "./button";
import Wrapper from "./wrapper";

import logo from "../images/img-main-stage.jpg";

const BG = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 10vh;
  background-size: cover !important;
  z-index: 0;
`;

export default ({
  image = logo,
  title = "",
  desc = "",
  to,
  actionLabel = "Anfrage Senden",
}) => {
  return (
    <Flex
      w="100vw"
      h={["calc(70vh - 100px)", "calc(100vh - 100px)"]}
      justifyContent="flex-end"
      relative
    >
      <BG style={{ background: `url(${image}) no-repeat center center` }} />
      <Wrapper justifyContent={["center", "flex-end"]} zIndex={8888}>
        <Flex
          bg="surface:setAlpha:0.9"
          alignItems="flex-start"
          w="100%"
          p={[20, 30]}
          maxWidth={500}
          shadow={50}
          style={{ backdropFilter: "blur(6px)" }}
        >
          <Text font="h3" as="h3" color="secondary">
            {title}
          </Text>
          <p>{desc}</p>
          {to ? (
            <Button mt={20} to={to}>
              {actionLabel}
            </Button>
          ) : null}
        </Flex>
      </Wrapper>
    </Flex>
  );
};
